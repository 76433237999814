import React, { useEffect, useRef, useState } from 'react';
import css from './SideForm.module.css';
import LoginForm from '../../containers/AuthenticationPage/LoginForm/LoginForm';


const SideFormComponent = props =>{
    const activeClassName = 'my-active-class';
    const {showForm,handleHideForm, submitLogin,inProgress} = props;

    let stylee = showForm?css.sideFormShow : css.sideForm;


    const view = <>
                    <div className={'px-3 py-1 ' + stylee}>
                        <div className={css.headerRow}>
                            <h4>Sign in</h4>
                            <button onClick={handleHideForm} className={css.closeBtn}>
                                <span aria-hidden="true" className={css.closeIcon}>&times;</span>
                                Close
                            </button>
                            
                        </div>
                       <hr/>

                       <LoginForm hideAccountCreation={true} className={css.loginForm} onSubmit={submitLogin} inProgress={inProgress}/>
                        
                    </div>

                    {showForm?<div id="sideFormOverlay" onClick={handleHideForm} className={css.sideFormOverlay}></div>:""}
                </>;

  return (
    
    <>
        {view}
    </>
    
    
  );
};

////
export default SideFormComponent;
